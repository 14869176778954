.App {
  .hide-for-web {
    display: none;
  }
}

.user-has-valid-subscription {
  .hide-for-subscriber {
    display: none;
  }
}

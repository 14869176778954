/*Columns*/
.wp-block-columns {
    @include xy-grid();
    @include xy-gutters($grid-margin-gutters, margin, right left, true);
    .wp-block-column {
        @include xy-cell(auto);
        @include xy-gutters($grid-margin-gutters, padding, right left, false);
        @include breakpoint(medium down) {
            flex: auto;
        }
        &.is-vertically-aligned-center {
            @include flex-align-self(middle);
        }
        &.is-vertically-aligned-bottom {
            @include flex-align-self(bottom);
        }
    }
    &.alignfull {
        @include alignfull;
    }
    .wp-block-group__inner-container & {
        @include xy-cell(auto);
        @include xy-gutters($grid-margin-gutters, margin, right left, true);
        @include xy-gutters($grid-margin-gutters, padding, right left, true);
        /*margin-left: 0;
        margin-right: 0;*/
        .wp-block-column {
            //@include xy-cell(auto);
            @include xy-gutters(10, margin, right left, true);
            @include xy-gutters(0, padding, right left, true);
            @include breakpoint(medium down) {
                flex: auto;
            }
        }
        //@include xy-gutters(0, padding, right left, false);
    }
}

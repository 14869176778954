p {
  .user-has-medium-font-size & {
    font-size: $global-font-size*1.1;
    @include breakpoint(medium) {
      font-size: $global-font-size*1.1*1.1;
    }
    &.lead {
      font-size: $global-font-size*1.1*1.1;
      @include breakpoint(medium) {
        font-size: $global-font-size*1.2*1.1;
      }
    }
    &.byline {
      font-size: rem-calc(15);
      @include breakpoint(medium) {
        font-size: rem-calc(17);
      }
    }
  }
  .user-has-large-font-size & {
    font-size: $global-font-size*1.2;
    @include breakpoint(medium) {
      font-size: $global-font-size*1.2*1.1;
    }
    &.lead {
      font-size: $global-font-size*1.2*1.1;
      @include breakpoint(medium) {
        font-size: $global-font-size*1.2*1.2;
      }
    }
    &.byline {
      font-size: rem-calc(17);
      @include breakpoint(medium) {
        font-size: rem-calc(19);
      }
    }
  }
}

ul,
ol {
  .user-has-medium-font-size & {
    font-size: $global-font-size*1.1;
    @include breakpoint(medium) {
      font-size: $global-font-size*1.1*1.1;
    }
  }
  .user-has-large-font-size & {
    font-size: $global-font-size*1.2;
    @include breakpoint(medium) {
      font-size: $global-font-size*1.2*1.1;
    }
  }
}

figcaption {
  .user-has-medium-font-size & {
    font-size: rem-calc(15);
    @include breakpoint(medium) {
      font-size: rem-calc(17);
    }
  }
  .user-has-large-font-size & {
    font-size: rem-calc(17);
    @include breakpoint(medium) {
      font-size: rem-calc(19);
    }
  }
}

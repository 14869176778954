.toolbox {
  font-family: sectra-grotesk, san-serif;
  position: fixed;
  bottom: rem-calc(15);
  right: rem-calc(15);
  @include breakpoint(large) {
    bottom: rem-calc(20);
    right: rem-calc(20);
  }
  z-index: 1;
  background: $light-gray;
  transition: all 0.2s linear;
  a {
    text-decoration: none;
  }
}

.scroll-down .toolbox {
  visibility: hidden;
  opacity: 0;
}

.scroll-up .toolbox {
  visibility: visible;
  opacity: 1;
}

.toolbar {
  @include flex();
  &__icon {
    padding: rem-calc(7) rem-calc(10);
    svg {
      display: block;
      height: rem-calc(20);
      &.close-sharing-menu {
        height: rem-calc(12);
        @include breakpoint(medium) {
          height: rem-calc(14);
        }
      }
    }
    &:first-child:not(:only-child) {
      padding: rem-calc(7) rem-calc(5) rem-calc(7) rem-calc(10);
    }
  }
  &__submenu {
    margin: rem-calc(7) 0;
    .description {
      display: block;
      font-size: small;
      border-bottom: 1px solid;
      padding-bottom: 5px;
      margin-bottom: 3px;
    }
    span,
    a {
      display: block;
      padding: 0 rem-calc(10);
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      &.set-fontsize {
        &__standard {
          font-size: $global-font-size;
          @include breakpoint(medium) {
            font-size: $global-font-size*1.1;
          }
        }
        &__medium {
          font-size: $global-font-size*1.1;
          @include breakpoint(medium) {
            font-size: $global-font-size*1.1*1.1;
          }
        }
        &__large {
          font-size: $global-font-size*1.2;
          @include breakpoint(medium) {
            font-size: $global-font-size*1.2*1.1;
          }
        }
      }
    }
  }
}

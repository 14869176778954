/*Media & Text Block*/

.wp-block-media-text {
    @include xy-grid();

    .wp-block-media-text {
        &__media {
            @include xy-cell(50%, null, 0, none);
        }

        &__content {
            @include xy-cell(50%, null, 0, none);
            @include xy-gutters($grid-margin-gutters, padding, left right top);
            @include flex-align-self(middle);
        }
    }
    
    &.is-vertically-aligned-top {
        .wp-block-media-text__content {
            @include flex-align-self(top);
        }
    }
    
    &.is-vertically-aligned-bottom {
        .wp-block-media-text__content {
            @include flex-align-self(bottom);
        }
    }

    &.is-stacked-on-mobile {
        @include breakpoint(small only) {
            .wp-block-media-text {
                &__media {
                    @include xy-cell(full, null, 0, none);
                }

                &__content {
                    @include xy-cell(full);
                }
            }
        }
    }

    &.has-media-on-the-right {
        .wp-block-media-text {
            &__media {
                order: 2;
            }

            &__content {
                order: 1;
            }
        }
    }
    
    &.alignfull {
        @include alignfull;
    }
}
// Reportagen margins & paddings

$rep-gutter: $global-margin;
$rep-gutter-large: $rep-gutter*2;

// Margin Mixins for Reportagen
@mixin rep-margin-all-50 {
  margin: $rep-gutter/2;
  @include breakpoint(large) {
    margin: $rep-gutter;
  }
}

@mixin rep-margin-all-100 {
  margin: $rep-gutter;
  @include breakpoint(large) {
    margin: $rep-gutter-large;
  }
}

@mixin rep-margin-all-150 {
  margin: $rep-gutter*1.5;
  @include breakpoint(large) {
    margin: $rep-gutter-large*1.5;
  }
}

@mixin rep-margin-all-200 {
  margin-top: $rep-gutter*2;
  @include breakpoint(large) {
    margin-top: $rep-gutter-large*2;
  }
}

@mixin rep-margin-top-50 {
  margin-top: $rep-gutter/2;
  @include breakpoint(large) {
    margin-top: $rep-gutter;
  }
}

@mixin rep-margin-top-100 {
  margin-top: $rep-gutter;
  @include breakpoint(large) {
    margin-top: $rep-gutter-large;
  }
}
@mixin rep-margin-top-150 {
  margin-top: $rep-gutter*1.5;
  @include breakpoint(large) {
    margin-top: $rep-gutter-large*1.5;
  }
}

@mixin rep-margin-top-200 {
  margin-top: $rep-gutter*2;
  @include breakpoint(large) {
    margin-top: $rep-gutter-large*2;
  }
}

@mixin rep-margin-bottom-50 {
  margin-bottom: $rep-gutter/2;
  @include breakpoint(large) {
    margin-bottom: $rep-gutter;
  }
}

@mixin rep-margin-bottom-100 {
  margin-bottom: $rep-gutter;
  @include breakpoint(large) {
    margin-bottom: $rep-gutter-large;
  }
}

@mixin rep-margin-bottom-150 {
  margin-bottom: $rep-gutter*1.5;
  @include breakpoint(large) {
    margin-bottom: $rep-gutter-large*1.5;
  }
}

@mixin rep-margin-bottom-200 {
  margin-bottom: $rep-gutter*2;
  @include breakpoint(large) {
    margin-bottom: $rep-gutter-large*2;
  }
}

@mixin rep-margin-left-50 {
  margin-left: $rep-gutter/2;
  @include breakpoint(large) {
    margin-left: $rep-gutter;
  }
}

@mixin rep-margin-left-100 {
  margin-left: $rep-gutter;
  @include breakpoint(large) {
    margin-left: $rep-gutter-large;
  }
}

@mixin rep-margin-left-150 {
  margin-left: $rep-gutter*1.5;
  @include breakpoint(large) {
    margin-left: $rep-gutter-large*1.5;
  }
}

@mixin rep-margin-left-200 {
  margin-left: $rep-gutter*2;
  @include breakpoint(large) {
    margin-left: $rep-gutter-large*2;
  }
}

@mixin rep-margin-right-50 {
  margin-right: $rep-gutter/2;
  @include breakpoint(large) {
    margin-right: $rep-gutter;
  }
}

@mixin rep-margin-right-100 {
  margin-right: $rep-gutter;
  @include breakpoint(large) {
    margin-right: $rep-gutter-large;
  }
}

@mixin rep-margin-right-150 {
  margin-right: $rep-gutter*1.5;
  @include breakpoint(large) {
    margin-right: $rep-gutter-large*1.5;
  }
}

@mixin rep-margin-right-200 {
  margin-right: $rep-gutter*2;
  @include breakpoint(large) {
    margin-right: $rep-gutter-large*2;
  }
}

// Padding Mixins for Reportagen

@mixin rep-padding-all-50 {
  padding: $rep-gutter/2;
  @include breakpoint(large) {
    padding: $rep-gutter;
  }
}

@mixin rep-padding-all-100 {
  padding: $rep-gutter;
  @include breakpoint(large) {
    padding: $rep-gutter-large;
  }
}

@mixin rep-padding-all-150 {
  padding: $rep-gutter*1.5;
  @include breakpoint(large) {
    padding: $rep-gutter-large*1.5;
  }
}

@mixin rep-padding-all-200 {
  padding: $rep-gutter*2;
  @include breakpoint(large) {
    padding: $rep-gutter-large*2;
  }
}

@mixin rep-padding-top-50 {
  padding-top: $rep-gutter/2;
  @include breakpoint(large) {
    padding-top: $rep-gutter;
  }
}

@mixin rep-padding-top-100 {
  padding-top: $rep-gutter;
  @include breakpoint(large) {
    padding-top: $rep-gutter-large;
  }
}
@mixin rep-padding-top-150 {
  padding-top: $rep-gutter*1.5;
  @include breakpoint(large) {
    padding-top: $rep-gutter-large*1.5;
  }
}

@mixin rep-padding-top-200 {
  padding-top: $rep-gutter*2;
  @include breakpoint(large) {
    padding-top: $rep-gutter-large*2;
  }
}

@mixin rep-padding-bottom-50 {
  padding-bottom: $rep-gutter/2;
  @include breakpoint(large) {
    padding-bottom: $rep-gutter;
  }
}

@mixin rep-padding-bottom-100 {
  padding-bottom: $rep-gutter;
  @include breakpoint(large) {
    padding-bottom: $rep-gutter-large;
  }
}

@mixin rep-padding-bottom-150 {
  padding-bottom: $rep-gutter*1.5;
  @include breakpoint(large) {
    padding-bottom: $rep-gutter-large*1.5;
  }
}

@mixin rep-padding-bottom-200 {
  padding-bottom: $rep-gutter*2;
  @include breakpoint(large) {
    padding-bottom: $rep-gutter-large*2;
  }
}

@mixin rep-padding-left-50 {
  padding-left: $rep-gutter/2;
  @include breakpoint(large) {
    padding-left: $rep-gutter;
  }
}

@mixin rep-padding-left-100 {
  padding-left: $rep-gutter;
  @include breakpoint(large) {
    padding-left: $rep-gutter-large;
  }
}

@mixin rep-padding-left-150 {
  padding-left: $rep-gutter*1.5;
  @include breakpoint(large) {
    padding-left: $rep-gutter-large*1.5;
  }
}

@mixin rep-padding-left-200 {
  padding-left: $rep-gutter*2;
  @include breakpoint(large) {
    padding-left: $rep-gutter-large*2;
  }
}

@mixin rep-padding-right-50 {
  padding-right: $rep-gutter/2;
  @include breakpoint(large) {
    padding-right: $rep-gutter;
  }
}

@mixin rep-padding-right-100 {
  padding-right: $rep-gutter;
  @include breakpoint(large) {
    padding-right: $rep-gutter-large;
  }
}

@mixin rep-padding-right-150 {
  padding-right: $rep-gutter*1.5;
  @include breakpoint(large) {
    padding-right: $rep-gutter-large*1.5;
  }
}

@mixin rep-padding-right-200 {
  padding-right: $rep-gutter*2;
  @include breakpoint(large) {
    padding-right: $rep-gutter-large*2;
  }
}

$percentages: 50, 100, 150, 200;

// Margin Classes

@each $percentage in $percentages {
  .rep-margin-all-#{$percentage} {
    margin: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      margin: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-margin-right-#{$percentage} {
    margin-right: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      margin-right: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-margin-left-#{$percentage} {
    margin-left: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      margin-left: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-margin-top-#{$percentage} {
    margin-top: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      margin-top: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-margin-bottom-#{$percentage} {
    margin-bottom: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      margin-bottom: $rep-gutter-large*$percentage/100;
    }
  }
}

// Padding Classes

@each $percentage in $percentages {
  .rep-padding-all-#{$percentage} {
    padding: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      padding: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-padding-left-#{$percentage} {
    padding-left: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      padding-left: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-padding-right-#{$percentage} {
    padding-right: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      padding-right: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-padding-top-#{$percentage} {
    padding-top: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      padding-top: $rep-gutter-large*$percentage/100;
    }
  }
}

@each $percentage in $percentages {
  .rep-padding-bottom-#{$percentage} {
    padding-bottom: $rep-gutter*$percentage/100;
    @include breakpoint(large) {
      padding-bottom: $rep-gutter-large*$percentage/100;
    }
  }
}

.no-margin {
  &__top {
    margin-top: 0 !important;
  }
  &__bottom {
    margin-bottom: 0 !important;
  }
  &__left {
    margin-left: 0 !important;
  }
  &__right {
    margin-right: 0 !important;
  }
}

.no-padding {
  &__top {
    padding-top: 0 !important;
  }
  &__bottom {
    padding-bottom: 0 !important;
  }
  &__left {
    padding-left: 0 !important;
  }
  &__right {
    padding-right: 0 !important;
  }
}

.account,
.bookmarks,
.faq-account {
  font-family: sectra-grotesk, san-serif;
  h3 {
    @include rep-margin-top-200;
  }
}

.user-menu {
  position: sticky;
  top: calc(#{$topbar-height} + env(safe-area-inset-top));
  @include breakpoint(large) {
    top: calc(#{$topbar-height-desktop} + env(safe-area-inset-top));
  }
  &__links {
    height: $topbar-height;
    @include breakpoint(large) {
      height: $topbar-height-desktop;
    }
    @include flex();
    @include flex-align(left, middle);
    a {
      height: rem-calc(40);
      @include breakpoint(large) {
        height: rem-calc(50);
      }
      @include flex();
      align-items: center;
      padding: 0 rem-calc(20);
      &:first-child {
        padding: 0 rem-calc(20) 0 0;
      }
    }
    svg {
      display: block;
      height: rem-calc(20);
      @include breakpoint(large) {
        height: rem-calc(30);
      }
    }
    &--text {
      font-size: rem-calc(17);
      @include breakpoint(large) {
        font-size: rem-calc(22);
      }
    }
  }
}

.bookmark-container  {
  .search-result {
    &__reportage {
      text-align: left;
      .title {
        font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
        }
        @include breakpoint(large) {
          font-size: rem-calc(map-deep-get($header-styles, large, h3, font-size));
          @include rep-margin-bottom-50;
        }}
    }
    .lead {
        @include breakpoint(medium) {
          @include rep-margin-bottom-100;
        }
        @include breakpoint(large) {
          @include rep-margin-bottom-50;
        }
    }
  }
  .bookmark-actions {
    @include rep-margin-top-100;
    @include breakpoint(large) {
      @include rep-margin-top-50;
    }
    @include rep-margin-bottom-100;
    &__icon {
      &:hover {
        cursor: pointer;
      }
      > svg {
        height: rem-calc(20);
        width: auto;
        @include breakpoint(large) {
          height: rem-calc(30);
        }
      }
    }
  }
  .offline-actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    .remove-icon {
      padding: rem-calc(10) rem-calc(10) 0;
      > svg {
        height: rem-calc(20);
        width: auto;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.faq-icons {
  height: rem-calc(40);
  @include rep-margin-bottom-100;
  @include breakpoint(medium) {
    height: rem-calc(60);
    @include rep-margin-bottom-150;
  }
  @include breakpoint(large) {
    @include rep-margin-bottom-100;
  }
  &__icon {
    height: 100%;
    margin-right: $rep-gutter;
    @include breakpoint(medium) {
      margin-right: $rep-gutter*1.5;
    }
  }
}

.taxonomy-list {
  &__container {
    width: 100%;
  }
  .submenu {
    color: $dark-gray;
    max-width: $max-menu-width;
    font-size: rem-calc(18);
    @include breakpoint(medium) {
      font-size: rem-calc(23);
    }
  }
  a.submenu__parent {
    color: $black;
    font-size: rem-calc(18);
    @include rep-padding-bottom-50;
    @include breakpoint(medium) {
      font-size: rem-calc(23);
    }
  }
  a {
    text-decoration: none;
  }
  .submenu__children {
    @include rep-margin-top-50;
  }
}
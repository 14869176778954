.login {
  font-family: sectra-grotesk, san-serif;
  @include breakpoint(medium) {
    font-size: $global-font-size*1.1;
  }
  padding: $global-padding 0;
  &__form {
    @include breakpoint(large) {
      margin-top: rem-calc(16);
    }
    button {
      @include rep-margin-top-150;
      @include breakpoint(large) {
        @include rep-margin-top-100;
      }
    }
  }
}

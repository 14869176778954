.block-reportage-zeitfenster {
  @include rep-margin-bottom-100;
  &__content {
    @include rep-padding-top-100;
    @include rep-padding-bottom-100;
    border-top: 2px solid;
    border-bottom: 2px solid;
    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.news-events {
  article {
    @include rep-margin-top-100;
    @include rep-padding-bottom-100;
    .article-header a {
      text-decoration: none;
    }
    &.news-article {
      a {
        &:not(.button):not(.wp-block-button__link) {
          text-decoration: none;
          color: $blue;
        }
      }
    }
    &.event-article {
      a {
        &:not(.button):not(.wp-block-button__link) {
          text-decoration: none;
          color: $red;
        }
      }
    }
  }
  .lead {
    @include rep-margin-bottom-100;
  }
  .wp-post-image {
    @include rep-margin-bottom-100;
  }

}

.featured-image {
  figcaption {
    @include rep-margin-bottom-100;
  }
}

.event-date,
.event-time {
  display: block;
}

.tribe-block {
  h3 {
    font-family: sectra-grotesk, san-serif;
  }
  &__venue__phone,
  &__venue__website {
    @include breakpoint(medium) {
      font-size: $global-font-size*1.1;
      margin-bottom: $paragraph-margin-bottom*2;
    }
  }
}

.footer {
  width: 100%;
  @include rep-padding-top-100;
  padding-bottom: $rep-gutter*2;
  @include breakpoint(large) {
    @include rep-padding-bottom-100;
  }
}
.inner-footer {
  max-width: $global-width;
  margin-left: auto;
  margin-right: auto;
}

/*Separators*/
hr {
    &.wp-block-separator {
        @include breakpoint(large) {
            margin: rem-calc(20) auto;
        }
        &.has-background {
            background-color: transparent !important;
        }
    }
}

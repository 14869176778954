body {
  -moz-font-feature-settings: "lnum" 1;
  -ms-font-feature-settings: "lnum" 1;
  -o-font-feature-settings: "lnum" 1;
  -webkit-font-feature-settings: "lnum" 1;
  font-feature-settings: "lnum" 1;
}

/* Paragraph Styles */

p {
  @include breakpoint(medium) {
    font-size: rem-calc(20);
    margin-bottom: $paragraph-margin-bottom*2;
  }
  &.has-drop-cap:first-letter {
    float: left;
    font-size: 7rem;
    line-height: 0.7;
    margin: .25rem .5rem 0 0;
    text-transform: uppercase;
    font-style: normal;
  }
  &.lead {
    line-height: 1.2;
    font-size: rem-calc(18);
    @include breakpoint(medium) {
      font-size: rem-calc(20);
    }
    &.has-lead-font-size-medium {
      font-size: rem-calc(30);
      line-height: 1.1;
      @include breakpoint(large) {
        font-size: rem-calc(40);
        line-height: 1.05;
      }
    }
    &.has-lead-font-size-large {
      font-size: rem-calc(40);
      line-height: 1.05;
      @include breakpoint(large) {
        font-size: rem-calc(60);
      }
    }
  }
  &.has-background {
    padding: 0 3px;
  }
}

ul,
ol {
  @include breakpoint(medium) {
    font-size: $global-font-size*1.1;
    margin-bottom: $paragraph-margin-bottom*2;
  }
}

/* Heading Styles */
h1,
h2,
h3,
h4,
h5 {
  margin-top: $global-margin;
  @include breakpoint(medium) {
    margin-top: $global-margin*2;
    margin-bottom: $global-margin*2;
  }
}

/*Byline*/
.byline {
  font-size: rem-calc(12);
  line-height: 1.25;
  letter-spacing: 1px;
  a {
    color: inherit;
  }
  @include breakpoint(medium) {
    font-size: rem-calc(15);
    line-height: 1.2;
  }
}

/*Figcaption*/
figcaption {
  font-family: sectra-grotesk, sans-serif;
  font-size: rem-calc(13);
  letter-spacing: 1px;
  line-height: 1.25;
  @include breakpoint(medium) {
    font-size: rem-calc(15);
    line-height: 1.2;
  }
}


/*Font sizes*/
.has-small-font-size {
  font-size: rem-calc(16);
  @include breakpoint(small only) {
    font-size: rem-calc(15);
  }
}
.has-medium-font-size {
  font-size: rem-calc(30);
  line-height: 1.1;
  @include breakpoint(large) {
    font-size: rem-calc(40);
    line-height: 1.05;
  }
}
.has-large-font-size {
  font-size: rem-calc(40);
  line-height: 1.05;
  @include breakpoint(large) {
    font-size: rem-calc(60);
  }
}
.has-x-large-font-size {
  font-size: rem-calc(60);
  line-height: 1.05;
  @include breakpoint(large) {
    font-size: rem-calc(80);
  }
}

/* Font weights */
.has-normal-font-weight {
  font-weight: normal;
}

.has-medium-font-weight {
  font-weight: 500;
}

.has-bold-font-weight {
  font-weight: bold;
}

/* Font styles */
.has-font-style-normal {
  font-style: normal;
}

.has-font-style-italic {
  font-style: italic;
}

.has-text-decoration-none,
.has-text-decoration-none a {
  text-decoration: none;
}

/* Text Transformation */
.is-uppercase {
  text-transform: uppercase;
}

.has-letter-spacing {
  letter-spacing: 1px;
}

address {
  font-style: normal;
  @include breakpoint(medium) {
    font-size: $global-font-size*1.1;
    margin-bottom: $paragraph-margin-bottom*2;
  }
}

.wp-block-code {
  white-space: break-spaces;
  margin-bottom: $paragraph-margin-bottom;
  @include breakpoint(medium) {
    margin-bottom: $paragraph-margin-bottom*2;
  }
}

.has-white-space-nowrap {
  white-space: nowrap;
}

.error404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  position: absolute;
  top: 0;
}

.error404 svg {
  max-width: 240px;
  height: auto;
}

.error404 h1 {
  margin: 1rem;
}
.error404 span {
  font-size: 4rem;
  margin-top: 1rem;
}
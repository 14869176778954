.block-reportage-header {
  // Header Image visibility based on selected style
  .header-image {
    display: none;
  }
  &.is-style-standard-image,
  &.is-style-full-width-image,
  &.is-style-centered-image {
    .header-image {
      display: block;
    }
  }
  h1 {
    @include rep-margin-top-100;
    @include rep-margin-bottom-100
    @include breakpoint(medium) {
      @include rep-margin-top-150;
      &.after-category-button {
        @include rep-margin-top-100;
      }
    }
  }
  &.is-style-standard-image {
    .header-image {
      @include rep-margin-top-100;
      > img {
        object-fit: cover;
        @include breakpoint(medium) {
          max-height: calc(100vh - 250px);
          @media screen and (max-height: 900px) {
            max-height: unset;
          }
        }
      }
    }
  }
  &.is-style-centered-image {
    text-align: center;
    .header-image {
      @include rep-margin-top-200;
      padding: 0 $rep-gutter-large*2;
      @include breakpoint(medium) {
        padding: 0 $rep-gutter-large*4;
      }
    }
    h1 {
      @include breakpoint(medium) {
        @include rep-margin-top-100;
      }
    }
  }
  &.is-style-full-width-image {
    .header-image {
      @include alignfull;
      padding: 0;
      > img {
        object-fit: cover;
        width: 100vw;
      }
    }
  }
  .category-button {
    @include rep-margin-top-100;
  }
  .lead {
    @include rep-margin-bottom-100;
  }
  .byline {
    @include rep-margin-bottom-200;
  }
  .reportage-tools {
    @include rep-margin-bottom-200;
    position: absolute;
  }
}

.intro-gradient {
  height: rem-calc(600);
  position: absolute;
  z-index: -1 !important;
  width: 100%;
  left: 0;
}

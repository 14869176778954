.content {
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  width: 100%;
  padding-top: $topbar-height;
  @include breakpoint(large) {
    padding-top: $topbar-height-desktop;
  }
  #page-footer {
    flex-grow: 1;
  }
}

.section-header {
  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding: .5rem 0;
  }
  z-index: 1;
  position: sticky;
  top: 0;
  transition: top 0.2s linear;
  body.scroll-up & {
    top: $topbar-height;
    @include breakpoint(large) {
      top: $topbar-height-desktop;
    }
  }
  body.scroll-down & {
    top: 0;
  }
}

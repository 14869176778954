/*Buttons*/
button,
.button,
.wp-block-button__link {
  display: inline-flex;
  //margin-top: $rep-gutter/2;
  margin-bottom: $rep-gutter;
  @include breakpoint(medium) {
    //margin-top: $rep-gutter;
    margin-bottom: $rep-gutter-large;
  }
  font-family: sectra-grotesk, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: inherit;
  color: $white;
  background: $black;
  @include breakpoint(medium) {
    font-size: rem-calc(20);
  }
  border: 0;
  border-radius: 2rem;
  &.no-border-radius {
    border-radius: 0;
  }
  padding: rem-calc(3) rem-calc(14);
  @include breakpoint(medium) {
    padding: rem-calc(7) rem-calc(20);
  }
  transition: opacity .2s;
  &:hover {
    color: $white;
    opacity: .75;
    cursor: pointer;
    @media (hover: none) {
      opacity: 1;
    }
  }
  &.aligncenter {
    text-align: center;
  }
  &.alignright {
    text-align: right;
  }
}

button,
.button {
  &.is-style-outline {
    color: $black;
    background: transparent;
    border: 2px solid;
    padding: rem-calc(3) rem-calc(12);
    @include breakpoint(medium) {
      padding: rem-calc(5) rem-calc(18);
    }
    &:hover {
      color: $black;
      opacity: .75;
      @media (hover: none) {
        opacity: 1;
      }
    }
  }
  &.is-style-disabled,
  &:disabled {
    background: $dark-gray;
    &:hover {
      opacity: unset;
      cursor: not-allowed;
    }
  }
}

.wp-block-buttons {
  @include flex;
  &.aligncenter,
  &.is-content-justification-center {
    @include flex-align(center, middle);
  }
  &.alignleft,
  &.is-content-justification-left,
  &{
    @include flex-align(left, middle);
  }
  &.alignright,
  &.is-content-justification-right {
    @include flex-align(right, middle);
  }
  .wp-block-button {
    &:not(:first-child) {
      margin-left: $rep-gutter/2;
    }
    &.has-font__gt-sectra {
      .wp-block-button__link {
        font-family: gt-sectra,serif;
      }
    }
    &:not(.is-style-outline) {
      .wp-block-button__link:hover {
        color: $white;
      }
    }
    &.is-style-outline {
      .wp-block-button__link {
        color: $black;
        background: transparent;
        border: 2px solid;
        padding: rem-calc(3) rem-calc(12);
        @include breakpoint(medium) {
          padding: rem-calc(5) rem-calc(18);
        }
      }
    }
  }
}

.category-button {
  display: block;
  &__name {
    display: inline-block;
    background: transparent;
    border: 2px solid;
    letter-spacing: 1px;
    font-weight: 500;
    @include breakpoint(large) {
      font-size: rem-calc(20);
    }
    padding: rem-calc(2) rem-calc(4);
    @include breakpoint(large) {
      padding: rem-calc(3) rem-calc(6);
    }
  }
}

$max-menu-width: $global-width - $global-margin*4;

body.menu-open {
  overflow: hidden;
}


.menu-container {
  font-family: sectra-grotesk, san-serif;
  background: $topbar-background;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  padding: $global-padding $global-padding 0;
  overflow-x: hidden;
  overflow-y: auto;
  margin: $topbar-height 0 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s, opacity .2s;
  @include breakpoint(large) {
    margin: $topbar-height-desktop 0 0;
  }
  a {
    text-decoration: none;
  }
  z-index: 2;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.menu {
  color: $black;
  max-width: $max-menu-width;
  margin: 0 auto;
  font-size: rem-calc(18);
  @include breakpoint(medium) {
    font-size: rem-calc(20);
  }
  ul {
    list-style: none;
    margin: 0;
    font-size: rem-calc(18);
    @include breakpoint(medium) {
      font-size: rem-calc(20);
    }
  }
}

.trigger-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 3;
  transition: transform 0.2s linear;
}

.scroll-down .trigger-menu-wrapper {
  transform: translate(0,-100%);
}

.scroll-up .trigger-menu-wrapper {
  transform: none;
}

.scroll-up:not(.menu-open) .trigger-menu-wrapper {
  background: $white;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;
  padding: 0 rem-calc(7.5) 0 0;
  @include breakpoint(large) {
    padding: 0 rem-calc(10) 0 0;
  }
  background: $topbar-background;
  height: $topbar-height;
  @include breakpoint(large) {
    height: $topbar-height-desktop;
  }
  .back-button {
    @include flex();
    align-items: center;
    height: rem-calc(40);
    padding: 0 rem-calc(15);
    @include breakpoint(large) {
      height: rem-calc(50);
      padding: 0 rem-calc(20);
    }
    body.menu-open & {
      visibility: hidden;
    }
  }
  body.archive &,
  body.single-autorin &,
  body.single-illustrator & {
    border-bottom: 2px solid $black;
  }
  &.menu-open {
    border-bottom: 2px solid $black;
    #open-menu {
      display: none;
    }
    #close-menu {
      display: block;
    }
  }
}

.logo,
.logo svg,
.menu-icon,
.menu-icons svg,
.back-button svg {
  display: block;
  height: rem-calc(20);
  @include breakpoint(large) {
    height: rem-calc(30);
  }
  img {
    height: 100%;
    width: auto;
  }
}

.menu-icons {
  @include flex();
  a {
    height: rem-calc(40);
    @include breakpoint(large) {
      height: rem-calc(50);
    }
    @include flex();
    align-items: center;
    padding: 0 rem-calc(7.5);
    @include breakpoint(large) {
      padding: 0 rem-calc(10);
    }
  }
  .user-initials,
  .subscription-link {
    font-size: rem-calc(15);
    @include breakpoint(large) {
      font-size: $global-font-size;
    }
    @include flex();
    box-sizing: border-box;
    align-items: center;
  }
  .subscription-link {
    padding: 0 rem-calc(3.5);
    @include breakpoint(large) {
      padding: 0 rem-calc(5);
    }
  }
}

.logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  @include breakpoint(medium) {
    width: rem-calc(142);
  }
  @include breakpoint(large) {
    width: rem-calc(212);
  }
}

.menu-icons {
  img {
    vertical-align: sub;
  }
  .subscription-link {
    @include breakpoint(468 down) {
      display: none;
    }
  }
}


#open-menu,
#close-menu {
  width: rem-calc(25);
  @include breakpoint(large) {
    width: rem-calc(38);
  }
}

#close-menu {
  display: none;
}

/*.menu-open {
  overflow: hidden;
}*/

.menu-search {
  position: relative;
  @include rep-margin-bottom-200;
  @include breakpoint(large) {
    margin-top: 25px;
  }
  .search-reset {
    position: absolute;
    right: 0;
    top: 22px;
    @include breakpoint(large) {
      top: 28px;
    }
    &:hover {
      cursor: pointer;
    }
    svg {
      height: rem-calc(16);
    }
  }
}

.search-feedback {
  position: relative;
  top: $global-margin;
}

.submenu {
  @include rep-margin-top-100;
  &__parent {
    span {
      color: $dark-gray;
      cursor: pointer;
    }
  }
  &__child {
    @media(hover: hover) and (pointer: fine) {
      span:hover {
        color: $black;
        cursor: pointer;
      }
    }
    @include breakpoint(medium) {
      display: inline-block;
      @include rep-margin-right-100;
    }
    @include breakpoint(large) {
      @include rep-margin-right-50;
    }
  }
  &__children {
    &--secondary {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: visibility .2s, opacity .2s;
      background: $topbar-background;
      padding: $topbar-height+$global-padding $global-padding $global-padding $global-padding;
      @include breakpoint(large) {
        padding: $topbar-height-desktop+$global-padding $global-padding $global-padding $global-padding;
      }
      overflow: auto;
      h4 {
        margin-bottom: 0;
      }
      &:not(.submenu-open) {
        > * {
          display: none;
        }
      }
    }
    &--letters {
      .close-submenu {
        display: none;
      }
      &.submenu-open {
        .close-submenu {
          display: inline;
        }
      }
    }
    &--container {
      max-width: rem-calc(600);
      margin: 0 auto;
      @include rep-padding-bottom-200;
    }
    &--container-meta {
      @include breakpoint(medium) {
        @include rep-margin-top-150;
      }
      @include rep-margin-bottom-100;
      small {
        display: block;
        line-height: 1;
      }
      h3 {
        @include rep-margin-top-50;
        @include rep-margin-bottom-50;
      }
    }
  }
  .submenu-open {
    visibility: visible;
    opacity: 1;
  }
  a.close-submenu{
    margin-left: rem-calc(10);
    svg {
      height: rem-calc(11);
      @include breakpoint(medium) {
        height: rem-calc(16);
      }
    }
    &.close-submenu__letter svg {
      @include breakpoint(medium) {
        height: rem-calc(14);
      }
    }
  }
  .letters-list {
    margin-top: $global-margin;
    a {
      display: inline-flex;
      margin-right: $global-margin;
      margin-bottom: $global-margin;
    }
  }
}

.grouped-list__container.has-submenu-open > *:not(.submenu-open) {
  display: none;
}

#sections-menu {
  @include rep-padding-top-100;
  @include rep-padding-bottom-100;
  border-top: 2px solid $dark-gray;
  border-bottom: 2px solid $dark-gray;
  li:not(:last-child) {
    @include rep-padding-bottom-100;
  }
}


.menu-footer {
  //z-index: -1;
  padding: $global-padding $global-padding $global-padding*4;
  @include breakpoint(large) {
    padding: $global-padding $global-padding $global-padding*1.5;
  }
  @include rep-margin-top-200;
  &__container {
    max-width: $max-menu-width;
    margin-left: auto;
    margin-right: auto;
  }
}
ul.menu__footer {
  column-count: 2;
  font-size: rem-calc(15);
  @include breakpoint(small only) {
    font-size: rem-calc(14);
  }
  a {
    color: $black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

#pages-menu {
  > li {
    @include rep-padding-bottom-100;
  }
}

.menu-item-has-children {
  color: $dark-gray;
  .menu-item {
    span:hover {
      color: $black;
      cursor: pointer;
    }
    @include breakpoint(medium) {
      display: inline-block;
      @include rep-margin-right-100;
    }
    @include breakpoint(large) {
      @include rep-margin-right-50;
    }
  }
}

.user-menu {
  transition: top 0.2s linear;
  body.scroll-down & {
    top: 0;
  }
}
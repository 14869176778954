.wp-block-group {
  @include xy-grid();
  @include xy-gutters($grid-margin-gutters, margin, right left, true);
  &__inner-container {
    @include xy-cell(auto);
    @include xy-gutters($grid-margin-gutters, padding, right left, false);
  }
  &.alignfull {
    @include alignfull;
  }
  &.alignwide {
    @include alignfull;
    @include flex-align(center,null);
    .wp-block-group__inner-container {
      max-width: map-get($breakpoints, 'large');
    }
  }
}

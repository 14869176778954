/*Colors*/
.has-red-color {
    color: $primary-color;
    &.wp-block-button__link {
        color: $primary-color !important;
    }
}
.has-red-background-color {
    background: $primary-color;

    &.wp-block-button__link {
        background-color: $primary-color !important;
        &:hover {
            background-color: scale-color($primary-color, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($primary-color,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($primary-color, $i * 0.1) !important;
        }
    }
}

.has-blue-color {
    color: $secondary-color;
    &.wp-block-button__link {
        color: $secondary-color !important;
    }
}
.has-blue-background-color {
    background-color: $secondary-color;
    &.wp-block-button__link {
        background-color: $secondary-color !important;
        &:hover {
            background-color: scale-color($secondary-color, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($secondary-color,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($secondary-color, $i * 0.1) !important;
        }
    }
}

.has-yellow-color {
    color: $warning-color;
    &.wp-block-button__link {
        color: $warning-color !important;
    }
}
.has-yellow-background-color {
    background-color: $warning-color;
    &.wp-block-button__link {
        background-color: $warning-color !important;
        &:hover {
            background-color: scale-color($warning-color, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($warning-color,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($warning-color, $i * 0.1) !important;
        }
    }
}

.has-black-color {
    color: $black;
    &.wp-block-button__link {
        color: $black !important;
    }
}
.has-black-background-color {
    background-color: $black;
    &.wp-block-button__link {
        background-color: $black !important;
        &:hover {
            background-color: scale-color($black, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($black,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($black, $i * 0.1) !important;
        }
    }
}

.has-light-gray-color {
    color: $light-gray;
    &.wp-block-button__link {
        color: $light-gray !important;
    }
}
.has-light-gray-background-color {
    background-color: $light-gray;
    &.wp-block-button__link {
        background-color: $light-gray !important;
        &:hover {
            background-color: scale-color($light-gray, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($light-gray,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($light-gray, $i * 0.1) !important;
        }
    }
}

.has-medium-gray-color {
    color: $medium-gray;
    &.wp-block-button__link {
        color: $medium-gray !important;
    }
}
.has-medium-gray-background-color {
    background-color: $medium-gray;
    &.wp-block-button__link {
        background-color: $medium-gray !important;
        &:hover {
            background-color: scale-color($medium-gray, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($medium-gray,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($medium-gray, $i * 0.1) !important;
        }
    }
}

.has-dark-gray-color {
    color: $dark-gray;
    &.wp-block-button__link {
        color: $dark-gray !important;
    }
}
.has-dark-gray-background-color {
    background-color: $dark-gray;
    &.wp-block-button__link {
        background-color: $dark-gray !important;
        &:hover {
            background-color: scale-color($dark-gray, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($dark-gray,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($dark-gray, $i * 0.1) !important;
        }
    }
}

.has-white-color {
    color: $white;
    &.wp-block-button__link {
        color: $white !important;
    }
}
.has-white-background-color {
    background-color: $white;
    &.wp-block-button__link {
        background-color: $white !important;
        &:hover {
            background-color: scale-color($white, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($white,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($white, $i * 0.1) !important;
        }
    }
}

.has-green-color {
    color: $green;
    &.wp-block-button__link {
        color: $green !important;
    }
}
.has-green-background-color {
    background-color: $green;
    &.wp-block-button__link {
        background-color: $green !important;
        &:hover {
            background-color: scale-color($green, $lightness: -15%) !important;
        }
    }
    @for $i from 1 through 10 {
        &.has-background-dim:before {
            background: rgba($green,0.5);
        }
        &.has-background-dim-#{ $i * 10 }:before {
            background: rgba($green, $i * 0.1) !important;
        }
    }
}
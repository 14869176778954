/*Image Block*/
.wp-block-image {
    @include rep-margin-top-200;
    @include rep-margin-bottom-200;
    img {
        margin-bottom: $rep-gutter/2;
        @include breakpoint(large) {
            margin-bottom: $rep-gutter-large/2;
        }
    }
    .aligncenter {
        text-align: center;
    }
    .alignleft {
        float: left;
        padding-right: $global-padding;
    }
    .alignright {
        float: right;
        padding-left: $global-padding;
    }
    &.alignfull {
        @include alignfull;
        img {
            object-fit: cover;
            width: 100vw;
        }
    }
    &.alignwide {
        @include alignfull;
        @include flex;
        @include flex-align(center,null);
        @include flex-direction(column);
        padding: 0 $global-padding;
        img,
        figcaption {
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include breakpoint(large) {
                max-width: map-get($breakpoints, 'xlarge');
                width: 100%;
            }
        }
    }

    &.is-style-centered {
        @include rep-margin-top-200;
        padding: 0 $rep-gutter-large*2;
        @include breakpoint(large) {
            padding: 0 $rep-gutter-large*4;
        }
        figcaption {
            text-align: center;
        }
    }
    &.is-style-remove-vertical-margins {
        margin-top: unset;
        margin-bottom: unset;
        img {
            margin-bottom: unset;
        }
    }
}

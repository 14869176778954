.block-comic-reportage {
  @include alignfull();
  img {
    vertical-align: top;
  }
  &.image-has-frame {
    .cell {
      &:first-child img {
        @include rep-padding-left-100;
        @include rep-padding-right-100;
        @include breakpoint(xlarge) {
          @include rep-padding-right-50;
        }
      }
      &:last-child img {
        @include rep-padding-left-100;
        @include breakpoint(xlarge) {
          @include rep-padding-left-50;
        }
        @include rep-padding-right-100;
      }
      &.single-image {
        img {
          @include rep-padding-left-100;
          @include rep-padding-right-100;
        }
      }
    }
  }
  .single-image {
    @include breakpoint(xlarge) {
      max-width: 50%;
    }
  }
}

.page {
  .article-header:not(.upcoming-event) {
    @include xy-grid(vertical,true);
    @include xy-gutters($grid-margin-gutters, margin, right left, true);
    .page-title {
      @include xy-cell(auto);
      @include xy-gutters($grid-margin-gutters, margin, right left, false);
      word-break: break-word;
    }
    &.text-center {
      .page-title {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &.page-header {
      &__medium-width {
        @include alignfull;
        @include flex-align(center,null);
        .page-title {
          max-width: map-get($breakpoints, 'large');
          @include xy-gutters($grid-margin-gutters, padding, right left, false);
        }
      }
      &__full-width {
        @include alignfull;
        @include xy-gutters($grid-margin-gutters, margin, right left, false);
        @include xy-gutters($grid-margin-gutters, padding, right left, false);
      }
    }
    .lead {
      @include xy-cell(auto);
      @include xy-gutters($grid-margin-gutters, margin, right left, false);
    }
  }
}

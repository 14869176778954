.block-reportage-section {
  header {
    > span {
      max-width: $column-inner-container-max-width + $rep-gutter*2;
      line-height: 1.1;
      padding: .5rem 0;
      font-size: rem-calc(24);
      @include breakpoint(large) {
        font-size: rem-calc(30);
      }
    }
  }
  &__small-width {
    header > span {
      max-width: $global-width - $rep-gutter*2;
    }
    .block-reportage-section__inner-container {
      max-width: $global-width - $rep-gutter*2;
    }
  }
  &__inner-container {
    max-width: $column-inner-container-max-width + $rep-gutter*2;
    .block-reportage-teaser {
      @include alignfull;
      max-width: unset;
    }
  }
  .wp-block-columns {
    @include xy-gutters($grid-margin-gutters, margin, right left, true);
    @include xy-gutters($grid-margin-gutters, padding, right left, true);
    .wp-block-column {
      @include xy-gutters($grid-margin-gutters, padding, right left, false);
      @include xy-gutters(0, margin, right left, false);
    }
  }
}

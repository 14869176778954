.block-reportage-teaser {
  &.image-position-left {
    @include breakpoint(medium) {
      .header-image {
        .column-inner-container {
          float: right
        }
      }
      .title-lead {
        .column-inner-container {
          float: left;
          max-width: rem-calc(640);
          @include breakpoint(medium only) {
            @include rep-padding-right-50;
          }
        }
        /*.category-button {
          @include breakpoint(medium only) {
            right: 0;
            @include rep-padding-right-50;
          }
        }*/
      }
    }
  }
  &.image-position-right {
    @include breakpoint(medium) {
      .header-image {
        @include flex-order(1);

        .column-inner-container {
          float: left;
        }
      }
      .title-lead {
        @include flex-order(0);
        .column-inner-container {
          @include breakpoint(large) {
            margin-left: auto;
            max-width: rem-calc(640);
          }
        }

        .category-button {
          @include breakpoint(large) {
            right: 0;
          }
        }
      }
    }
  }
  .header-image {
    &:not(.image-has-no-frame){
      .column-inner-container {
        width: auto;
        @include breakpoint(large) {
          //max-width: $global-width;
          max-width: rem-calc(660);
        }
      }
      img {

      }
    }
    &.image-has-frame {
      @include breakpoint(small only) {
        @include rep-padding-top-100;
        @include rep-padding-left-50;
        @include rep-padding-right-50;
        padding-bottom: 0;
      }
      @include breakpoint(medium) {
        @include rep-padding-left-50;
        @include rep-padding-top-100;
        //@include rep-padding-bottom-100;
        padding-right: 0;
      }
      &.is-right-column-image {
        @include breakpoint(medium) {
          @include rep-padding-top-100;
          @include rep-padding-bottom-100;
          @include rep-padding-right-50;
          padding-left: 0;
        }
      }
      &.is-left-column-image {
        @include breakpoint(medium) {
          @include rep-padding-bottom-100;
        }
      }
      &__plus_one {
        .column-inner-container {
          max-width: rem-calc(410);
        }
        img {
          @include rep-padding-top-200;
          @include rep-padding-left-200;
          @include rep-padding-right-200;
          /*@include breakpoint(medium) {
            @include rep-padding-all-200;
          }*/
          @include breakpoint(large) {
            @include rep-padding-top-100;
            @include rep-padding-left-100;
            @include rep-padding-right-100;
            //@include rep-padding-all-100;
          }
        }
      }
      &__plus_two {
        .column-inner-container {
          max-width: rem-calc(410);
        }
        img {
          @include rep-padding-top-150;
          @include rep-padding-left-200;
          @include rep-padding-right-200;
        }
      }
    }
    &.image-has-no-frame {
      padding: 0;
      margin: 0;
      position: relative;
      @include breakpoint(small only) {
        width: 100%;
      }
      &.is-full-width-image {
        width: 100%;
        img {
          object-fit: cover;
          width: 100vw;
        }
      }
      &.is-left-column-image,
      &.is-right-column-image {
        @include breakpoint(medium) {
          width: 50%;
          font-size: 0;
        }
      }
    }
  }
  .title-lead {
    @include breakpoint(medium) {
      @include flex;
      flex-wrap: wrap;
    }
    position: relative;

    h2 {
      &.has-title-font-size-large {
        font-size: rem-calc(40);
        line-height: 1.05;
        @include breakpoint(large) {
          font-size: rem-calc(60);
        }
      }

      &.has-title-font-size-medium {
        font-size: rem-calc(30);
        line-height: 1.1;
        @include breakpoint(large) {
          font-size: rem-calc(40);
          line-height: 1.05;
        }
      }
      &.has-title-font-size-small {
        line-height: 1.2;
        font-size: rem-calc(18);
        @include breakpoint(medium) {
          font-size: rem-calc(20);
        }
      }
    }
    .category-button {
      width: calc(100% - 10px);
      @include breakpoint(medium) {
        &.stick-to-top {
          position: absolute;
          top: 0;
        }
        width: calc(100% - 20px);
        max-width: $global-width;
        @include rep-margin-top-100;
      }
      @include breakpoint(large) {
        width: calc(100% - 40px);
      }
    }
    .column-inner-container {
      align-self: center;
      /*@include breakpoint(medium) {
        max-width: $global-width;
      }*/
    }
  }
  .has-1-columns {
    .column-inner-container {
      margin: 0 auto;
    }
    &__no-image,
    &__image-has-no-frame {
      .title-lead {
        .category-button {
          width: 100%;
        }
        .column-inner-container {
          float: unset;
          width: 100%;
          @include breakpoint(large) {
            max-width: $column-inner-container-max-width;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .has-2-columns {
    .header-image {
      &.image-has-frame,
      &.image-has-frame__plus_one,
      &.image-has-frame__plus_two {
        .category-button {
          @include breakpoint(medium) {
            display: none;
          }
        }
      }
      .column-inner-container {
        margin: 0 auto;
      }
    }
    .title-lead {
      &.image-has-frame,
      &.image-has-frame__plus_one,
      &.image-has-frame__plus_two {
        .category-button {
          @include breakpoint(small only) {
            display: none;
          }
        }
      }
    }
  }
  .lead {
    @include rep-margin-bottom-100;
    @include breakpoint(medium) {
      @include rep-margin-bottom-200;
    }
    @include breakpoint(large) {
      @include rep-margin-bottom-100;
    }
  }
  .byline {
    @include rep-margin-bottom-100;
    @include breakpoint(medium) {
      @include rep-margin-bottom-200;
    }
    @include breakpoint(large) {
      @include rep-margin-bottom-100;
    }
  }
}

.category-button {
  &.expand {
    max-width: rem-calc($column-inner-container-max-width) !important;
    margin-left: auto;
    margin-right: auto;
    @include rep-margin-top-150;
  }
}

.has-1-columns {
  .header-image {
    .category-button {
      display: block;
      @include rep-margin-top-150;
      @include breakpoint(large) {
        @include rep-margin-top-100;
      }
    }
    &.image-has-frame {
      .category-button {
        margin-top: 0;
        @include rep-margin-bottom-100;
      }
    }
  }
  .title-lead {
    .category-button {
      display: none;
    }
  }
}

.has-1-columns__image-has-no-frame {
  .header-image {
    .category-button {
      display: none;
    }
  }
  .title-lead {
    .category-button {
      display: block;
      max-width: rem-calc($column-inner-container-max-width);
      margin-left: auto;
      margin-right: auto;
      @include rep-margin-top-150;
    }
  }
}

.has-2-columns {
  .header-image {
    .category-button {
      display: block;
      @include rep-margin-top-150;
      @include breakpoint(medium) {
        display: none;
      }
    }
    &.image-has-frame {
      .category-button {
        margin-top: 0;
        @include rep-margin-bottom-100;
      }
    }
  }
  .title-lead {
    .category-button {
      display: none;
      @include breakpoint(medium) {
        display: block;
        position: absolute;
      }
    }
  }
}

.has-2-columns__image-has-no-frame {
  .header-image {
    .category-button {
      display: none;
    }
  }
  .title-lead {
    .category-button {
      display: block;
      @include rep-margin-top-150;
      @include breakpoint(medium) {
        position: absolute;
      }
    }
  }
}

button {
  &.loading,
  &.loading-more {
    color: transparent;
    opacity: 1;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      width: $global-font-size;
      height: $global-font-size;
      margin: auto;
      border: 3px solid $white;
      border-top: 3px solid transparent;
      border-radius: 50%;
      animation: load-animate infinite linear 1s;
    }
  }
}

@-webkit-keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

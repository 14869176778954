.single-reportage .content,
.reportage {
  background: $light-gray;
  .entry-content > div.grid-x > div.cell {
    z-index: 0;
    & > * {
      z-index: 1;
    }
  }
}

.reportage-footer {
  @include rep-padding-top-100;
  &__title {
    p {
      @include rep-margin-bottom-100;
    }
  }
  .grid-container {
    @include breakpoint(large) {
      max-width: map-get($breakpoints, 'xlarge');
    }
  }
  .related-reportage {
    @include rep-padding-top-100;
    @include breakpoint(large) {
      @include rep-padding-bottom-100;
    }
    text-decoration: none;
    &:hover {
      color: lighten($black,25%);
    }
    &.search-result {
      &__serie,
      &__reportage {
        .title {
          padding-top: 0;
          border-top: 0;
        }
      }
    }
    .byline {
      margin-bottom: 0;
      @include breakpoint(medium down) {
        @include rep-padding-bottom-100;
      }
    }
    &:not(:last-child) {
      .byline {
        @include breakpoint(medium down) {
          border-bottom: 2px solid;
        }
      }
    }
  }
}

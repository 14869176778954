.archive-page {
  &__authors,
  &__illustrators {
    ul {
      margin-left: 0;
      list-style: none;
      a {
        font-family: sectra-grotesk, san-serif;
        color: $dark-gray;
        text-decoration: none;
      }
    }
  }
}
.img-search-result {
  &__with-border {
    max-width: calc(100vw - #{map-get($grid-margin-gutters,small)});
    @include breakpoint(large) {
      max-width: calc(100vw - #{map-get($grid-margin-gutters,large)});
    }
  }
}

@mixin box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}

@mixin alignfull {
  width: 100vw !important;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}

/*Embed Block*/

.wp-block-embed {
  .wp-block-embed__wrapper {
    @include responsive-embed(12 by 8);
  }
  /*Video Block*/
  &.is-type-video {
    &.wp-embed-aspect-16-9 {
      .wp-block-embed__wrapper {
        @include responsive-embed(16 by 9);
      }
    }
    &.wp-embed-aspect-4-3 {
      .wp-block-embed__wrapper {
        @include responsive-embed(4 by 3);
      }
    }
  }
  &.is-provider-soundcloud {
    .wp-block-embed__wrapper {
      @include responsive-embed(3 by 1);
    }
  }
  &.wp-embed-aspect-21-9 {
    .wp-block-embed__wrapper {
      @include responsive-embed(21 by 9);
    }
  }
}

// Checkboxes
input[type="checkbox"] {
  display: none;
}

label {
  &.form__label--for-checkbox {
    cursor: pointer;
  }
  &.screen-reader-text {
    position: absolute;
    left: -10000px;
  }
}

input[type="checkbox"] + span {
  &:before {
    content: '';
    display: inline-block;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNTEyIDUxMkgwVjBoNTEyek0yOS4xOCA0ODIuODJoNDUzLjY0VjI5LjE4SDI5LjE4eiIvPjwvc3ZnPg==) 0px center no-repeat;
    position: relative;
    top: 2px;
    margin-right: 6px;
    width: $global-font-size;
    height: $global-font-size;
    @include breakpoint(medium) {
      width: $global-font-size*1.1;
      height: $global-font-size*1.1;
    }
  }
}

input[type="checkbox"]:checked + span {
  &:before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDk3LjUgMEgwdjUxMmg1MTJWMHpNMjkuMTggNDkuNjlMMjM1LjQ5IDI1NiAyOS4xOCA0NjIuMzF6bTIwLjUxLTIwLjUxaDQxMi42MkwyNTYgMjM1LjQ5ek0yNTYgMjc2LjVsMjA2LjMxIDIwNi4zMkg0OS42OXptMjAuNTEtMjAuNUw0ODIuODIgNDkuNjl2NDEyLjYyeiIvPjwvc3ZnPg==) 0px center no-repeat;
  }
}

// Radios
input[type="radio"] {
  display: none;
}

label {
  &.form__label--for-radio {
    cursor: pointer;
  }
}

input[type="radio"] + span {
  &:before {
    content: '';
    display: inline-block;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMjU2IDUxMkMxMTQuODQgNTEyIDAgMzk3LjE2IDAgMjU2UzExNC44NCAwIDI1NiAwczI1NiAxMTQuODQgMjU2IDI1Ni0xMTQuODQgMjU2LTI1NiAyNTZ6bTAtNDgzQzEzMC44MyAyOSAyOSAxMzAuODMgMjkgMjU2czEwMS44MyAyMjcgMjI3IDIyNyAyMjctMTAxLjgzIDIyNy0yMjdTMzgxLjE3IDI5IDI1NiAyOXoiLz48L3N2Zz4=) 0px center no-repeat;
    position: relative;
    top: 2px;
    margin-right: 6px;
    width: $global-font-size;
    height: $global-font-size;
    @include breakpoint(medium) {
      width: $global-font-size*1.1;
      height: $global-font-size*1.1;
    }
  }
}

input[type="radio"]:checked + span {
  &:before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMjU2IDBDMTE0Ljg0IDAgMCAxMTQuODQgMCAyNTZzMTE0Ljg0IDI1NiAyNTYgMjU2IDI1Ni0xMTQuODQgMjU2LTI1NlMzOTcuMTYgMCAyNTYgMHptMCA0ODNDMTMwLjgzIDQ4MyAyOSAzODEuMTcgMjkgMjU2UzEzMC44MyAyOSAyNTYgMjlzMjI3IDEwMS44MyAyMjcgMjI3LTEwMS44MyAyMjctMjI3IDIyN3oiLz48Y2lyY2xlIGN4PSIyNTYiIGN5PSIyNTYiIHI9IjEyOCIgdHJhbnNmb3JtPSJyb3RhdGUoLTEzLjI4IDI1Ni4wMzIgMjU2LjA0NykiLz48L3N2Zz4=) 0px center no-repeat;
  }
}

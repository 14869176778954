input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid $dark-gray;
  outline: 0;
  color: $black;
  padding: 8px 0 6px;
  @include breakpoint(large) {
    padding: 12px 0 6px;
  }
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: inherit;
  cursor: text;
  top: 22px;
  /*@include breakpoint(large) {
    top: 32px;
  }*/
}

label,
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: rem-calc(12);
  @include breakpoint(large) {
    font-size: rem-calc(15);
  }
  color: $dark-gray;
}

.form__field:focus ~ .form__label {
  color: $red;
}

.form__field:focus {
  border-bottom: 1px solid $red;
}

.section-container {
  .news-event-teaser {
    h5 {
      @include rep-margin-bottom-50;
    }
    .lead {
      margin-bottom: 0;
      @include rep-padding-bottom-200;
      @include breakpoint(large) {
        @include rep-padding-bottom-100;
      }
      border-bottom: 2px solid;
    }
    @include last-child {
      .lead {
        border-bottom: 0;
      }
    }
    &:hover {
      opacity: .85;
    }
  }
  .button {
    margin-top: 0;
    @include rep-margin-bottom-150;
  }
}

.next-events {
  .event-date-time {
    @include rep-margin-top-100;
  }
  h5 {
    @include rep-margin-top-50;
  }
  .event-date,
  .event-time {
    display: block;
  }
}

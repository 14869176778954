.archive-reportagen {
  a {
    text-decoration: none;
  }
  &__header {
    @include rep-margin-top-100;
    &--meta {
      line-height: 1;
      margin-bottom: $rep-gutter;
    }
    h1 {
      line-height: 1;
    }
  }
  &__counter {
    span {
      display: block;
    }
  }
  &__biography {
    @include rep-margin-top-100;
    > *:last-child {
      margin-bottom: 0;
    }
    &--illustrator {
      > *:last-child {
        @include rep-padding-bottom-100;
        border-bottom: 2px solid $black;
      }
    }
  }
  &__content {
    @include rep-margin-top-100;
    figure {
      @include rep-padding-bottom-200;
      img {
        display: block;
      }
      figcaption {
        @include rep-margin-top-100;
      }
    }
  }
}

/*Blockquote & Pullquote Styles*/

cite {
    font-style: unset;
    color: inherit;
}

/*Blockquote*/

blockquote {
    &.wp-block-quote {
        &.is-style-large {
            p {
                font-size: $global-font-size*2;
                @include breakpoint(small only) {
                    font-size: $global-font-size*1.5;
                }
            }
        }
    }
}

/*Pullquote*/
.wp-block-pullquote {
    border-top: rem-calc(4) solid;
    border-bottom: rem-calc(4) solid;
    &.is-style-solid-color {
        border-top: 0;
        border-bottom: 0;
        padding: $global-padding;
    }
    blockquote {
        text-align: center;
        p {
            font-size: $global-font-size*2;
            @include breakpoint(small only) {
                font-size: $global-font-size*1.5;
            }
        }
    }
    cite {
        text-transform: uppercase;
        @include font-wide;
    }
    &.alignfull {
        @include alignfull;
        blockquote {
            max-width: $global-width;
            margin: 0 auto;
        }
    }
}
.lazy-load-image-background.opacity {
    background: rgba(211, 211, 211, .25);
}

.lazy-load-image-background.opacity > img {
    opacity: 0;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
    background: transparent;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded > img {
    opacity: 1;
    transition: opacity .3s;
}
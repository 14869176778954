.magazin-header {
  &__cover {
    @include rep-margin-top-200;
    @include breakpoint(large) {
      @include rep-margin-top-100
    }
    img {
      @include breakpoint(360px up) {
        max-width: rem-calc(320);
      }
      @include breakpoint(small only) {
        @include rep-padding-left-200;
        @include rep-padding-right-200;
      }
    }
  }
  &__actions {
    .pdf-download {
      margin-top: -$rep-gutter/2;
      @include breakpoint(medium) {
        margin-top: -$rep-gutter;
      }
    }
    @include rep-margin-top-50;
    @include rep-margin-bottom-100;
    svg {
      display: block;
      height: rem-calc(20);
      &.offline-icon {
        height: rem-calc(26);
      }
      @include breakpoint(large) {
        height: rem-calc(28);
        &.offline-icon {
          height: rem-calc(36);
        }
      }
    }
    &--icons {
      @include flex();
      @include flex-align(center);
    }
    &--icon {
      height: rem-calc(40);
      @include breakpoint(large) {
        height: rem-calc(50);
      }
      @include flex();
      align-items: center;
      padding: 0 rem-calc(10);
      @include breakpoint(large) {
        padding: 0 rem-calc(15);
      }
      &:hover {
        cursor: pointer;
      }
      &.loading-more {
        color: transparent;
        opacity: 1;
        justify-content: center;
        align-items: center;
        &:after {
          content: '';
          position: absolute;
          width: $global-font-size;
          height: $global-font-size;
          margin: auto;
          border: 2px solid $black;
          border-top: 2px solid transparent;
          border-radius: 50%;
          animation: load-animate infinite linear 1s;
        }
        >svg {
          visibility: hidden;
        }
      }
    }
  }
}

.magazin-footer {
  @include rep-margin-bottom-100;
 .grid-container {
    max-width: $global-width;
   .related-serie {
     &:hover {
       color: lighten($black,25%);
     }
     .byline {
       margin-bottom: 0;
       @include rep-padding-bottom-100;
       span {
         display: inline;
       }
     }
     &__reportage {
       text-align: center;
       .title {
         @include rep-padding-top-100;
         @include rep-margin-bottom-50;
         border-top: 2px solid;
       }
     }
     &__serie {
       .title {
         display: block;
         @include rep-padding-top-100;
         @include rep-margin-bottom-50;
         border-top: 2px solid;
         font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
         @include breakpoint(medium){
           font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
         }
       }
     }
   }
 }
}

.sold-out {
  display: block;
  letter-spacing: 3px;
  @include rep-margin-top-50;
  @include rep-margin-bottom-100
  @include breakpoint(medium) {
    @include rep-margin-top-100;
    @include rep-margin-bottom-200;
  }
}

  @media print, screen and (min-width: 64em) {
.menu-icons [role="button"], .menu-icons button {
  height: 2.77778rem;
  padding: 0 .55556rem;
}
  }
.menu-icons [role="button"], .menu-icons button {
  height: 2.22222rem;
  align-items: center;
  padding: 0 .41667rem;
}
.menu-icons [role="button"], .menu-icons button {
  display: flex;
}

.menu-icons button:focus{
  border-radius: 0;
  outline-color: transparent;
  outline-style: none;
}

.menu-icons {
    align-items: center;
}

.main-menu-toggle {
  background: none;
  color: black;
  margin: 0;
  padding: 0;
  border-radius: none;
} 
.search-result {
  color: $black;
  &:hover {
    color: lighten($black,25%);
  }
  .byline {
    margin-bottom: 0;
    @include rep-padding-bottom-100;
    span {
      display: inline;
    }
  }
  &__reportage {
    text-align: center;
    .title {
      @include rep-padding-top-100;
      @include rep-margin-bottom-100;
      border-top: 2px solid;
    }
    .lead {
      @include breakpoint(medium only) {
        @include rep-margin-bottom-100;
      }
    }
  }
  &__serie {
    .title {
      display: block;
      @include rep-padding-top-100;
      @include rep-margin-bottom-100;
      @include breakpoint(large) {
        @include rep-margin-bottom-50;
      }
      border-top: 2px solid;
      font-size: rem-calc(18);
      @include breakpoint(medium) {
        font-size: rem-calc(20);
      }
    }
    .lead {
      @include breakpoint(medium) {
        @include rep-margin-bottom-100;
      }
      @include breakpoint(large) {
        @include rep-margin-bottom-50;
      }
    }
  }
}

.img-search-result {
  &__with-border {
    img {
      padding: 0 $rep-gutter-large;
      @include breakpoint(medium) {
        padding: 0 $rep-gutter-large*2;
      }
    }
  }
  &__full-width {
    figure {
      @include breakpoint(small only) {
        @include alignfull;
        figcaption {
          padding: 0 $global-padding;
        }
      }
    }
  }
}

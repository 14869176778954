/*Alignment classes*/
.has-text-align-center {
    text-align: center;
}
.has-text-align-right {
    text-align: right;
}

.full-width {
    @include alignfull;
}

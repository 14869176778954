/*Cover Block*/

.wp-block-cover, .wp-block-cover-image {
    position: relative;
    //background: transparent;
    &:not([class*="-background-color"]) {
        &.has-background-dim:before {
            background: rgba($black, .5);
        }
        @for $i from 1 through 10 {
            &.has-background-dim.has-background-dim-#{ $i * 10 }:before {
                background: rgba($black, $i * 0.1);
            }
	    }
    }
    &.has-background-dim:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
    background-size: cover;
    background-position: 50%;
    min-height: rem-calc(430);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &.alignfull {
        @include alignfull;
    }

    &.has-parallax {
		background-attachment: fixed;

		// Mobile Safari does not support fixed background attachment properly.
		// See also https://stackoverflow.com/questions/24154666/background-size-cover-not-working-on-ios
		// Chrome on Android does not appear to support the attachment at all: https://issuetracker.google.com/issues/36908439
		@supports (-webkit-overflow-scrolling: touch) {
			background-attachment: scroll;
		}

		// Remove the appearance of scrolling based on OS-level animation preferences.
		@media (prefers-reduced-motion: reduce) {
			background-attachment: scroll;
		}
	}

    .wp-block-cover__inner-container {
        z-index: 0;
        color: $black;
    }
}

.archive-magazin {
  padding: $global-padding;
  &__cover-cell {
    padding-top: $rep-gutter;
    @include breakpoint(medium) {
      padding-top: $rep-gutter-large;
    }
    &--cover {
      display: inline-block;
      img {
        max-height: rem-calc(460);
        width: auto;
        padding: 0 $rep-gutter*3;
      }
    }
    &--issue {
      @include rep-margin-top-100;
      font-size: $global-font-size;
      @include breakpoint(large) {
        font-size: $global-font-size*1.1;
      }
    }
    &--buttons {
      .button {
        margin-top: $rep-gutter/2;
        margin-bottom: $rep-gutter/2;
        @include breakpoint(medium) {
          margin-top: $rep-gutter;
          margin-bottom: $rep-gutter;
        }
      }
    }
  }
  &__next-issue {
    @include rep-margin-top-100;
    @include breakpoint(medium only) {
      @include rep-margin-top-200;
    }
    @include breakpoint(large) {
      font-size: $global-font-size;
    }
  }
}

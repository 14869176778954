.snackbar {
  font-family: sectra-grotesk, san-serif;
  position: fixed;
  bottom: rem-calc(15);
  left: rem-calc(15);
  @include breakpoint(large) {
    bottom: rem-calc(20);
    left: rem-calc(20);
  }
  z-index: 2;
  background-color: $black;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem-calc(7) rem-calc(10);
  height: auto;
  max-width: calc(100% - 30px);
  @include breakpoint(large) {
    max-width: calc(100% - 40px);
  }
  animation: fadein .5s, fadeout .5s 2.9s
}

.snackbar svg {
  height: 0.8rem;
  margin-left: 1rem;
}

.snackbar svg:hover {
  cursor: pointer;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
